import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Renderer2, Inject, PLATFORM_ID, LOCALE_ID, NgZone, DEFAULT_CURRENCY_CODE, ElementRef, AfterViewInit, ViewContainerRef, AfterViewChecked, ComponentRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, Event as RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AuthService, UserState } from './shared/auth/auth.service';
import { CartService } from './shared/cart/cart.service';
import { UtilsService } from './shared/services/utils.service';
import { NavigationService } from './shared/navigation/navigation.service';
import { Page, Settings } from './shared/navigation/pages.model';
import { OfferService } from './offer/offer.service';
import { Product } from './offer/offer.model';
import { Subject, Subscription, interval, timer } from 'rxjs';
import { take, skip, filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Client } from './auth/auth.model';
import { UserService } from './user/user.service';
import { SideNavComponent } from './shared/sidenav/side-nav.component';
import { Discount } from './shared/cart/cart.model';
import { utils } from './shared/helpers/utils';
import { CartComponent } from './shared/cart/cart.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('drawer') mainDrawer: SideNavComponent;
  @ViewChild('cart') cartDrawer: SideNavComponent;
  @ViewChild('cartvc', {read: ViewContainerRef}) cartvc: ViewContainerRef;
  @ViewChild('footervc', {read: ViewContainerRef}) footervc: ViewContainerRef;

  public pageName = environment.name;
  public languageMenuVisible = false;
  public languageList = [
    { code: '', label: 'Polski' },
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français' },
    { code: 'de', label: 'Deutsch' },
    { code: 'it', label: 'Italian' },
    { code: 'es', label: 'Spanish' },
  ];
  public currencyMenuVisible = false;
  public currencyList = [
    { code: 'PLN', label: 'PLN' },
    { code: 'EUR', label: 'EUR' },
    { code: 'USD', label: 'USD' },
  ];
  public hideBreadcrumbs = true;
  public hideDiscountPopup = true;
  public isLoggedIn = false;
  public isMobile = true;
  public drawerTop = 70;
  public maxHeight = 0;
  public ga4HasFired = false;
  public allowed = [1];
  public mobilePath = [];
  public last_activated_route = null;
  public navigation: Page[] = [];
  public navigationFooter: Page[] = [];
  public loading = false;
  public cartRef: ComponentRef<CartComponent>;
  public cartInited = false;
  public favItems = 0;
  public cdn = environment.cdn;
  public base = environment.base;
  public search = false;
  public search_term = '';
  public settings: Settings;
  public cookie_visible = true;
  public cookie_content = '';
  public products: Product[] = [];
  public breadcrumbs = [];
  public discountSelected: Discount;
  public discountTimerSub: Subscription;
  public pagesPerSession = 0;

  public path_account = 'konto';
  public path_login = 'zaloguj';
  public path_favorites = 'schowek';
  public path_search = '/';
  public path_offer_details = '/';
  public path_extra_1 = '/';
  public path_extra_2 = '/';


  public path_account_profile = '';
  public path_account_orders = '';
  public path_account_invoices = '';
  public path_account_discounts = '';
  public path_account_returns = '';
  public path_account_users = '';
  public path_account_files = '';
  public path_logout = '';

  public detection = 0;

  @ViewChild('term', {static: false}) term: ElementRef;
  @ViewChild('promocarousel', {static: false}) promocarousel: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(LOCALE_ID) public localeId: string, @Inject(DEFAULT_CURRENCY_CODE) public currency: string, 
    private router: Router, private activatedRoute: ActivatedRoute, private zone: NgZone, private auth: AuthService, public cartService: CartService, public utilsService: UtilsService,
    private navigationService: NavigationService, private renderer: Renderer2, private usersService: UserService, 
    private cdr: ChangeDetectorRef, private breakpointObserver: BreakpointObserver, public viewRef: ViewContainerRef) {
   
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event, activatedRoute);
    });
    this.navigationService.currentPage.subscribe(this.dynamicPageChanged); 

    const baseDevice = this.navigationService.mobile.getValue();
    if (baseDevice == 'desktop') {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  
    if (isPlatformBrowser(this.platformId)) {
      utilsService.setLoading();
      this.breakpointObserver
        .observe(['(min-width: 768px)', '(min-width: 1279px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            if (state.breakpoints['(min-width: 768px)'] == true && state.breakpoints['(min-width: 1279px)'] == false) {
              this.isMobile = true;
              this.navigationService.mobile.next('tablet');
              this.cdr.markForCheck();
            } else if (state.breakpoints['(min-width: 1279px)'] == true) {
              this.isMobile = false;
              this.navigationService.mobile.next('desktop');
              this.cdr.markForCheck();
            }
          } else {
            this.isMobile = true;
            this.navigationService.mobile.next('mobile');
            this.cdr.markForCheck();
          }
      });

      this.auth.isLoggedIn.subscribe((state: UserState) => {
        this.isLoggedIn = state.logged;

        if (state) {
          this.allowed = [1,2];
        } else {
          this.allowed = [1];
        }

        if (this.isLoggedIn && isPlatformBrowser(this.platformId)) {

          const userId = +localStorage.getItem('user_id');
          const userRole = localStorage.getItem('user_role');
          // const currency = localStorage.getItem('user_currency');
          const userState = localStorage.getItem('user_state') || 'confirmed';
          if (userState == 'unconfirmed') {

            const dialogResponse = new Subject<any>();

            dialogResponse.subscribe(data => {
              let client = new Client();
              client.id = userId;
              client.state = 1;
              client.confirmations = JSON.stringify(data);
              this.usersService.change(client).pipe(take(1)).subscribe(result => {
                this.utilsService.openDialog({
                  isSnackbar: 'success',
                  content:  `Twoje preferencje zostały zapisane`,
                  actionButtons: [{ text: 'OK', onAction: () => true}],
                }, this.viewRef);

                localStorage.removeItem('user_state');
              });
            });

            this.utilsService.openDialog({
              childComponent: 'LazyUserConfirmComponent',
              closeDialogSubject: dialogResponse
            }, this.viewRef);

          } else {
            let uuid = localStorage.getItem('uuid') || null;
            this.usersService.favorites(userId, uuid).pipe(take(1)).subscribe(favs => {
              this.cartService.favtItems.next(favs.items || []);
            });
          }

        } else {
          localStorage.removeItem('user_token');
          localStorage.removeItem('user_id');
          localStorage.removeItem('user_role');
          localStorage.removeItem('user_state');
          localStorage.removeItem('user_scopes');
          localStorage.removeItem('user_currency');
        }
        this.cdr.markForCheck();
      });

      this.utilsService.isFullscreen.subscribe((state: boolean) => {
        if (state) {
          this.renderer.addClass(document.body, 'fullscreen');
          this.renderer.addClass(document.body, 'disabled');
        } else {
          this.renderer.removeClass(document.body, 'fullscreen');
          this.renderer.removeClass(document.body, 'disabled');
        }
      });
      this.cartService.favtItems.pipe(skip(1)).subscribe(this.favChanged);
    }
  }

  navigationInterceptor = (event: RouterEvent, activatedRoute: ActivatedRoute) => {

    if (event instanceof NavigationStart) {
      this.last_activated_route = activatedRoute.snapshot?.firstChild?.url || '';
      this.utilsService.setLoading();
      if (this.mainDrawer) this.mainDrawer.close();
    }

    if (event instanceof NavigationEnd) {

      this.breadcrumbs = [];
      activatedRoute.firstChild.url.forEach((url) => {

        const routeData = activatedRoute.firstChild.snapshot?.data;
        if (routeData) {
          if (routeData.hideBredcrumbs) {
            this.hideBreadcrumbs = true;
          } else {
            this.hideBreadcrumbs = false;
          }
          if (routeData.hideDiscountPopup) {
            this.hideDiscountPopup = true;
          } else {
            this.hideDiscountPopup = false;
          }
          // if (routeData.goFullscreen) {
          //   this.isFullscreen = true;
          // } else {
          //   this.isFullscreen = false;
          // }
        }
        let last = '';
        console.log(url);
        for (const part of url) {
          const path = last + part.path;
          const crumb = this.router.config.find(x => x.path === path);
          if (crumb) {
            if (crumb.data['name'] && crumb.data['name'] != 'Zobacz wszystkie') this.breadcrumbs.push({label: crumb.data['name'], path: path});
          }
          last = path + '/';
        }

        if (this.breadcrumbs.length) {
          this.navigationService.breadcrumbs.next(this.breadcrumbs);
        }

        this.navigationService.setMeta(
          routeData.title,
          routeData.keywords,
          routeData.description,
          routeData.canonical
        );
      });

      if (isPlatformBrowser(this.platformId)) {

        if (!this.ga4HasFired) {
          this.ga4HasFired = true;
          this.utilsService.gtmCustom('PageInit');
        } else {
          this.utilsService.gtmCustom('PageView');
        }

        if (this.pagesPerSession == 4) {
          this.utilsService.gtmCustom('pagesPerSession', {pageCount: 4});
        }
        this.pagesPerSession++;

        const gclid = activatedRoute.snapshot.queryParamMap.get('gclid');
        const fbclid = activatedRoute.snapshot.queryParamMap.get('fbclid');
        const utm_source = activatedRoute.snapshot.queryParamMap.get('utm_source') || null;
        const utm_medium = activatedRoute.snapshot.queryParamMap.get('utm_medium') || null;
        const utm_campaign = activatedRoute.snapshot.queryParamMap.get('utm_campaign') || null;
        const utm_term = activatedRoute.snapshot.queryParamMap.get('utm_term') || null;
        const utm_content = activatedRoute.snapshot.queryParamMap.get('utm_content') || null;
        const saved =  this.navigationService.getClickId().value;
        const stored = localStorage.getItem('a_click') || false;
        if (gclid || fbclid || saved || stored) {
          let type: string, click: string;
          if (gclid) {
            type = 'google'; 
            click = gclid;
            localStorage.setItem('a_type', type);
            localStorage.setItem('a_click', click);
          } else if (fbclid) {
            type = 'facebook'; 
            click = fbclid;
            localStorage.setItem('a_type', type);
            localStorage.setItem('a_click', click);
          } else if (saved) {
            type = saved.type; 
            click = saved.click;
          } else if (stored) {
            type = localStorage.getItem('a_type') || 'not found'; 
            click = stored;
          }

          let uuid = localStorage.getItem('uuid') || null;
          if (uuid == null) {
            uuid = utils.uuidv4();
            localStorage.setItem('uuid', uuid);
          }
          let id_client = localStorage.getItem('user_id') || null;

          this.navigationService.google_click({id_client: id_client, uuid: uuid, type: type, utm_source: utm_source, utm_medium: utm_medium, utm_campaign: utm_campaign, utm_term: utm_term, utm_content: utm_content, click: click, page: activatedRoute.snapshot?.firstChild?.url?.join('/') || '-'}).subscribe();
          if (gclid || fbclid || stored) {
            this.navigationService.clickId.next({type: type, click: click});
          }
        }
      }


      this.utilsService.clearLoading();
    }
  }

  ngOnInit() {
    this.navigationService.navigation.subscribe((setup: any) => {
      this.settings = setup.settings;
      this.navigation = setup.menu;

      let discounts = setup.discounts;
      if (discounts && discounts?.length) {
        this.cartService.discounts$.next(discounts);
        discounts.forEach(discount => { //get for discount popup
          if (+discount.visible == 5 && !this.discountSelected) {
            this.discountSelected = discount;
          }
        });
      }

      this.navigationFooter = setup.footer;
      this.cookie_visible = !!+this.settings.cookies_use;
      this.cookie_content = this.settings.cookies_message;

      //setup offer url
      this.path_offer_details = this.router.config.find(x => x.data?.module === 'OfferDetailsModule')?.data.path || '/';
      this.path_search = this.router.config.find(x => x.data?.module === 'OfferModule' && x.data?.level == 0)?.path || '/';
      this.path_account = this.router.config.find(x => x.data?.id === -991)?.path || '/';
      this.path_login = this.router.config.find(x => x.data?.id === -980)?.path || '/';
      this.path_favorites = this.router.config.find(x => x.data?.id === -1005).path;
      this.path_extra_1 = this.router.config.find(x => x.data?.template === 'lazy2.component')?.path || '/';
      this.path_extra_2 = this.router.config.find(x => x.data?.template === 'lazy3.component')?.path || '/';

      this.path_logout = this.router.config.find(x => x.data?.id === -984).path;
      this.path_account_profile = this.router.config.find(x => x.data?.id === -992).path;
      this.path_account_orders = this.router.config.find(x => x.data?.id === -993).path;
      this.path_account_returns = this.router.config.find(x => x.data?.id === -994).path;
      this.path_account_discounts = this.router.config.find(x => x.data?.id === -995).path;
      this.path_account_invoices = this.router.config.find(x => x.data?.id === -1002).path;
      this.path_account_files = this.router.config.find(x => x.data?.id === -1004).path;

      this.showCookies();
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadFooter();
      this.initCarousel();
      this.loadCart();
      if (environment.production) {
        const source = timer(2500);
        const subscribe = source.subscribe(val => {
          this.loadLiveChat();
        })
      }
    }
  }

  ngAfterViewChecked(): void {
    this.detection++;
    // console.log('base ', this.detection);
  }

  switchMenuLevel($event: Event, page: Page, element: HTMLElement) {

      $event.preventDefault();
      if ($event.type == 'mouseenter' && this.isMobile) return;

      if ($event.type == 'click' && (!this.isMobile || this.isMobile && (!page.children || page.children.length == 0))) {
          this.mobilePath = [];
          this.maxHeight = 0;
          this.router.navigate([page.path]);
      } else if((!page.children || page.children.length == 0) && +page.level == 0) { 
        this.mobilePath = [];
        this.maxHeight = 0;
      } else if(page.children && page.children.length > 0) {
        this.maxHeight = this.recursiveHeight(element, (+page.level == 1 ? element.parentElement.offsetHeight : 0)) + 16;
        if (this.isMobile) {
          this.mobilePath[+page.level] = page;
        } else {
          this.recursiveAdd(page);
        }
      }
  }

  closeMenu() {
    if (!this.isMobile) {
    this.mobilePath = [];
    this.maxHeight = 0;
    }
  }

  recursiveAdd(page: Page) {
    this.mobilePath[+page.level] = page;
    if (page.children && page.children.length > 0) {
      this.recursiveAdd(page.children[0]);
    }
  }

  recursiveHeight(element: HTMLElement, height: number) {
    const children = element.getElementsByTagName('ul')[0];
    if (children){ 
        const h = this.recursiveHeight(children, children.offsetHeight);
        height += h;
    } 
    return height;
  }

  toggleDrawer() {
    let top = 40 + document.body.getBoundingClientRect().top;
    if (top >= 0 && top <= 40) {
      this.drawerTop = window.innerHeight - (70+top);
    } else {
      this.drawerTop = window.innerHeight - 70; //negative
    }
    this.mainDrawer.toggle();
  }

  async loadCart() {
    if (this.cartvc) {
      const { CartComponent } = await import('./shared/cart/cart.component');
      const cmp = CartComponent;
      this.cartRef = this.cartvc.createComponent(cmp);
      this.cartRef.instance.drawerToggle.subscribe(e => {
        if (e == true && this.cartInited) {
          this.showCart();
        } else {
          this.hideCartDrawer();
        }
        this.cartInited = true;
      });
      this.cartRef.changeDetectorRef.detectChanges();
    }
  }

  async loadFooter() {
    if (this.footervc) {
      const { FooterComponent } = await import('./shared/footer/footer.component');
      const cmp = FooterComponent;
      let footerRef = this.footervc.createComponent(cmp);
      footerRef.changeDetectorRef.detectChanges();
    }
  }

  async loadLiveChat() {
    if (this.footervc) {
      const { LiveChatWidgetComponent } = await import('@livechat/widget-angular');
      const cmp = LiveChatWidgetComponent;
      let chatRef = this.footervc.createComponent(cmp);
      chatRef.instance.license = '15171852';
    }
  }

  showDrawer() {
    this.mainDrawer.open();
    this.renderer.addClass(document.body, 'disabled');
  }

  showCart() {
    if(!this.cartRef) this.loadCart();
    this.cartDrawer.open();
    this.renderer.addClass(document.body, 'disabled');
    // this.cdr.detectChanges();
  }

  hideCartDrawer() {
    this.cartDrawer.close();
    this.renderer.removeClass(document.body, 'disabled');
    // this.cdr.detectChanges();
  }

  favChanged = (products: Product[]) => {
    this.favItems = products?.length;
    this.cdr.markForCheck();
  }

  dynamicPageChanged = (page: any) => {

    let label = null;
    if ('name' in page) {
      label = page.name;
    }
    if ('title' in page) {
      label = page.title;
    }
    if ('categories' in page) {

      // const category = page.categories.find((x: any) => x.path === this.last_activated_route);
      if (this.last_activated_route) {

        while (this.last_activated_route.length) {
          const category = page.categories.find((x: any) => x.path === this.last_activated_route.join('/'));
          if (category) {
            let add = true;
            if (this.breadcrumbs.filter(e => e.path === category.path).length > 0) {
              add = false;
            }
            if (add) this.breadcrumbs.unshift({ label: category.name, path: category.path });
          }
          this.last_activated_route.pop();
        }
        // this.cdr.detectChanges();
      } else if (typeof page.categories !== 'undefined' && page.categories.length > 0){
        page.categories.forEach(page => {
            this.breadcrumbs.push({ label: page.name, path: page.path });
        });
        // this.cdr.detectChanges();
      }
    }
    if (label) {
      this.breadcrumbs.push({ label: label, path: 'none' });
      this.cdr.markForCheck();
    }

    if (this.breadcrumbs.length) {
      this.navigationService.breadcrumbs.next(this.breadcrumbs);
    }
  }

  showDiscountPopup() {
    if (this.discountSelected && this.hideDiscountPopup == false) {
      const user_discount_popup = +localStorage.getItem('user_discount_popup') || 0;
      if (user_discount_popup < 2) {
        const source = timer(90000);
        source.pipe(take(1)).subscribe(val => {

          this.utilsService.openDialog({
            childComponent: 'LazyDiscountCodeComponent',
            data: this.discountSelected,
          });
          let user_discount_popup = +localStorage.getItem('user_discount_popup') || 0;
          if (user_discount_popup < 2) {
            user_discount_popup++;
            localStorage.setItem('user_discount_popup', ''+user_discount_popup);
          }

        });
      }
    }
  }

  openDiscountDialog(discount: any) {
    this.utilsService.openDialog({
      childComponent: 'LazyDiscountCodeComponent',
      data: discount
    });
  }

  showCookies() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.cookie_visible && localStorage.getItem('cookies') === null) { // 

        const dialogResponse = new Subject<any>();

       
        dialogResponse.subscribe(data => {
          localStorage.setItem('cookies', 'true');
          let additional = {};
          data.forEach(consent => {
              additional[consent.name] = consent.checked;
              localStorage.setItem(consent.name, consent.checked);
          });
          this.utilsService.gtmCustom('consent', additional);
          this.showDiscountPopup();
        });

        this.utilsService.openDialog({
          childComponent: 'LazyCookieConfirmComponent',
          content: this.cookie_content,

          closeDialogSubject: dialogResponse
        }, this.viewRef);
        
      } else {
        //sent consent
        const consents = ['functionality_storage','personalization_storage','security_storage','analytics_storage', 'ad_personalization', 'ad_user_data', 'ad_storage'];
        const gtm = {};
        consents.forEach(consent => {
          let c = localStorage.getItem(consent);
          if (c != null) gtm[consent] = c;
        });
        this.utilsService.gtmCustom('consent', gtm);
        this.showDiscountPopup();
      }
    }
  }

  triggerPhone($event, phone: string) {
    $event.preventDefault();
    this.utilsService.gtmCustom('phone_click', {page_location: 'header'});
    window.location.href='tel:'+phone;
  }

  triggerReservation() {
    this.utilsService.gtmCustom('button_reservation', {page_location: 'header'});
  }

  initCarousel() {
    if (isPlatformBrowser(this.platformId) && this.promocarousel) {
      let l = this.promocarousel.nativeElement.children.length;
        this.zone.runOutsideAngular( () => {
          let current = 0;
          var children = [...this.promocarousel.nativeElement.children];
          interval(3500).pipe(filter(() => !document.hidden)).subscribe((x) => {
            current++;            
            if (current > l-1) {
              current = 0;
            }

            children.forEach((child, i) => {
              if (i == current) {
                child.style.visibility = "visible";
                child.style.zIndex = 3;
                child.style.transform = "translateY(0)";
              } 
              else if(i == current-1 || (current == 0 && i == l-1)) {
                child.style.visibility = "visible";
                child.style.zIndex = 3;
                child.style.transform = "translateY(-100%)";
              } else {
                child.style.visibility = "none";
                child.style.zIndex = 1;
                child.style.transform = "translateY(100%)";
              }
            })

          });
        });
      // }
    }
  }

}

